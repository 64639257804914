@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import '../../../node_modules/@mds/theme/src/assets/themes/planzer-light/theme.css';
@import '../../../node_modules/@mds/angular-layout-primeng/src/lib/assets/primeflex.scss';
@import '../../../node_modules/@mds/icons8/src/assets/styles/style-mds.css';
@import '../../../node_modules/@mds/icons8/src/assets/styles/style-pg.css';

html,
body {
  height: 100%;
}

.center-grid-message {
  white-space: break-spaces;
  max-width: unset;
  text-align: unset;
}

.reactive-form {
  @extend .p-0;
  @extend .xxl\:col-4;
  @extend .xl\:col-5;
  @extend .lg\:col-5;
  @extend .md\:col-6;
  @extend .col-12;

  > *:not(:first-child) {
    @extend .mt-3;
  }

  > mds-page-buttons:last-of-type {
    @extend .mt-6;
  }
}

.reactive-wide-form {
  @extend .p-0;
  @extend .xxl\:col-8;
  @extend .xl\:col-10;
  @extend .lg\:col-10;
  @extend .md\:col-12;
  @extend .col-12;

  > *:not(:first-child) {
    @extend .mt-3;
  }

  > mds-page-buttons:last-of-type {
    @extend .mt-6;
  }
}

.wide-form {
  @extend .xl\:col-6;
  @extend .col-12;

  > * {
    @extend .mb-3;
  }
}

.p-success {
  color: var(--success);
}

.p-warning {
  color: var(--warning);
}

.mds-tab-content {
  //tab content can expand
  overflow-y: unset !important;
}

// fix overflow in panel
.p-multiselect .p-multiselect-panel {
  max-width: 100%;
}

// fix https://github.com/primefaces/primeng/issues/13001 for angular 15
.p-confirm-dialog-accept i {
  display: none;
}

//handle table overflow
.p-datatable-table {
  table-layout: fixed;
}

// hide picklist move all buttons
.hide-picklist-transfer-all-buttons .p-picklist-transfer-buttons > button:nth-child(2) {
  display: none;
}

// align picklist header and search height
.p-picklist .p-picklist-header {
  display: flex;
  align-items: center;
  min-height: 5.4375rem;
}

// fix picklist height
.p-picklist-list {
  min-height: 16rem !important;
  max-height: 16rem !important;

  @media screen and (max-width: 1200px) {
    min-height: unset !important;
  }
}

// disable focus on checkbox
.p-checkbox.p-checkbox-focused {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  border-color: none !important;
}

td,
iam-conditional-link {
  > a,
  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 960px) {
      max-width: 60vw;
      overflow: unset;
      white-space: unset;
      text-align: right;
      overflow-wrap: break-word;
    }
  }

  > a {
    @extend .no-underline;

    color: var(--primary-color);
    display: block;
  }

  > a:hover {
    text-decoration: underline !important;
  }

  @media screen and (max-width: 960px) {
    gap: 1rem;
  }
}
